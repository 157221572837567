import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const TaskCharts = ({
  filtered,
  setFiltered,
  chartFiltered,
  setChartFiltered,
  setSelectedTasks,
  subthemes,
  activeTab,
  setActiveTab,
  colors,
  subthemeItemsCounts,
  overdueTasks,
  todayTasks,
  soonTasks,
  toDoTasks,
}) => {
  const { t } = useTranslation();

  // Maps task types to their respective data
  const overdueLabel = t("overdue", { ns: "general" });
  const todayLabel = t("today", { ns: "general" });
  const soonLabel = t("soon", { ns: "general" });
  const toDoLabel = t("to_do", { ns: "general" });

  // Maps task types to their respective data using translated labels
  const taskMap = {
    [overdueLabel]: overdueTasks,
    [todayLabel]: todayTasks,
    [soonLabel]: soonTasks,
    [toDoLabel]: toDoTasks,
  };

  // Filter subthemes that have all zero values across both charts
  const filteredSubthemes = subthemeItemsCounts.filter((sub) => {
    const hasControlsData =
      sub.overdueCount.controls ||
      sub.todayCount.controls ||
      sub.upcomingCount.controls ||
      sub.toDoCount.controls;

    const hasMonitoringData =
      sub.overdueCount.monitoring ||
      sub.todayCount.monitoring ||
      sub.upcomingCount.monitoring ||
      sub.toDoCount.monitoring;

    return hasControlsData || hasMonitoringData; // Keep only if at least one chart has data
  });

  // Generates chart data using only filtered subthemes
  const generateChartData = (key) => ({
    labels: filteredSubthemes.map((sub) => sub.subtheme),
    datasets: [
      {
        label: overdueLabel,
        backgroundColor: colors.red,
        hoverBackgroundColor: colors.darkred,
        data: filteredSubthemes.map((item) => item.overdueCount[key]),
      },
      {
        label: todayLabel,
        backgroundColor: colors.orange,
        data: filteredSubthemes.map((item) => item.todayCount[key]),
      },
      {
        label: soonLabel,
        backgroundColor: colors.yellow,
        data: filteredSubthemes.map((item) => item.upcomingCount[key]),
      },
      {
        label: toDoLabel,
        backgroundColor: colors.green,
        borderColor: colors.darkgreen,
        hoverBackgroundColor: colors.darkgreen,
        data: filteredSubthemes.map((item) => item.toDoCount[key]),
      },
    ],
  });

  const chartDataControls = generateChartData("controls");
  const chartDataMonitoring = generateChartData("monitoring");

  const handleBarClick = (chartType, chartData) => (evt, item) => {
    if (item.length > 0) {
      const datasetIndex = item[0].datasetIndex;
      const labelIndex = item[0].index;

      // Get the selected subtheme label (instead of index)
      const selectedBarLabel = chartData.labels[labelIndex];
      const selectedDatasetLabel = chartData.datasets[datasetIndex].label;

      const subtheme = subthemes?.find((sub) => sub.description === selectedBarLabel);

      if (subtheme) {
        const filteredTasks = taskMap[selectedDatasetLabel]?.[chartType]?.filter(
          (task) => {
            if (chartType === "controls") {
              return task.control.risk.subtheme.id === parseInt(subtheme.id);
            }
            return task.monitoring.controls.some(
              (control) => control.risk.subtheme.id === parseInt(subtheme.id)
            );
          }
        );

        if (filteredTasks) {
          setFiltered(true);
          setChartFiltered(true);

          if (chartType === "controls") {
            setSelectedTasks({ controls: filteredTasks, monitoring: [] });
            if (activeTab !== "controls") {
              setActiveTab("controls");
            }
          } else {
            setSelectedTasks({ controls: [], monitoring: filteredTasks });
            if (activeTab !== "monitoring") {
              setActiveTab("monitoring");
            }
          }
        }
      }
    }
  };

  const options = {
    scale: { ticks: { precision: 0 } },
    indexAxis: "y",
    plugins: {
      legend: { display: true, labels: { color: colors.lightgrey } },
    },
    scales: {
      x: {
        stacked: true,
        suggestedMax: 10,
        ticks: { color: colors.lightgrey },
        grid: { color: colors.grey },
      },
      y: {
        stacked: true,
        ticks: { color: colors.lightgrey },
        grid: { color: colors.grey },
      },
    },
    onClick: handleBarClick,
  };

  return (
    <div className="row row-cols-2 justify-content-center text-center p-3">
      <div className="col">
        <h4 style={{ color: colors.lightgrey }}>Controls</h4>
        <Bar
          tabIndex="0"
          data={chartDataControls}
          options={{ ...options, onClick: handleBarClick("controls", chartDataControls) }}
          title={t("control chart", { ns: "screenreader" })}
          alt={t("control chart", { ns: "screenreader" })}
        />
      </div>
      <div className="col">
        <h4 style={{ color: colors.lightgrey }}>Monitoring</h4>
        <Bar
          tabIndex="0"
          data={chartDataMonitoring}
          options={{ ...options, onClick: handleBarClick("monitoring", chartDataMonitoring) }}
          title={t("monitoring chart", { ns: "screenreader" })}
          alt={t("monitoring chart", { ns: "screenreader" })}
        />
      </div>
    </div>
  );
};

export default TaskCharts;