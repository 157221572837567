import React, {useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
  MotifTabNavigation as MotifTabNav,
  MotifTabControl,
  } from '@ey-xd/motif-react'

import FetchDashboardData from './FetchDashboardData'
import OverviewSwitch from './OverviewSwitch'
import TableFilter from './TableFilter';
import TaskTable from './TaskTable';
import { tooltitle, colors } from '../../utils/utils';

const CompletedTasks = () => {
  
  const { t } = useTranslation();
  const { userRole, isSuperuser } = useOutletContext();
  const { isLoading, setIsLoading } = useLoading();

  const navigate = useNavigate();

  const [overviewSwitch, setOverviewSwitch] = useState(true);

  const [activeTab, setActiveTab] = useState('controls');

  const [filtered, setFiltered] = useState(false);
  const [chartFiltered, setChartFiltered] = useState(false); // not used on the completed tasks page

  const [originalTasks, setOriginalTasks] = useState({ controls: [], monitoring: [] });
  const [tasks, setTasks] = useState({controls: [], monitoring: [],});
  const [selectedTasks, setSelectedTasks] = useState([])
  
  const [themes, setThemes] = useState([]);
  const [subthemes, setSubthemes] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [entities, setEntities] = useState([]);
  const [countries, setCountries] = useState([]);

  const completed = 1; // only fetch completed tasks from database

  FetchDashboardData(completed, setOriginalTasks, tasks, setTasks, setSelectedTasks, setSubthemes, setThemes, setEntities, setCountries, setFiscalYears, setIsLoading);

  return (
  <>
  <title>{t('completed_tasks', {ns: 'menu'})} | {tooltitle}</title>
  <div className="d-flex justify-content-center">
    <h1>{t('completed_tasks', {ns: 'menu'})}</h1>
  </div>
    {userRole === 'Admin' && (
  <OverviewSwitch tabIndex="0" originalTasks={originalTasks} tasks={tasks} setTasks={setTasks} setSelectedTasks={setSelectedTasks} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} completed={true} />
    )}
   
    <div className="row justify-content-center mx-1 my-4">
      <MotifTabNav controlled='true'>
        <MotifTabControl onClick={() => setActiveTab('controls')} className={activeTab === 'controls' ? 'motif-active' : ''}>{t('controls', {ns: 'general'})}</MotifTabControl>
        <MotifTabControl tabIndex="0" onClick={() => setActiveTab('monitoring')} className={activeTab === 'monitoring' ? 'motif-active' : ''}>{t('monitoring', {ns: 'general'})}</MotifTabControl>
      </MotifTabNav>
    </div>
    <div className="row align-items-center">
      <TableFilter colors={colors} activeTab={activeTab} filtered={filtered} setFiltered={setFiltered} setChartFiltered={setChartFiltered} originalTasks={originalTasks} tasks={tasks} selectedTasks={selectedTasks} setSelectedTasks={setSelectedTasks} subthemes={subthemes} themes={themes} entities={entities} countries={countries} fiscalYears={fiscalYears} completed={1} />
    </div>
      <TaskTable selectedTasks={selectedTasks} activeTab={activeTab} navigate={navigate} completed={1} hideStartdate={false} hideDeadline={true} sortStartdate='desc' overviewSwitch={overviewSwitch} />
    </>
  )
}

export default CompletedTasks