import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifTable,
    MotifIcon,
    MotifIconButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifInput,
    MotifSelect,
    MotifOption,
    MotifInlineMessage,
    MotifErrorMessage,
    MotifCheckbox
} from '@ey-xd/motif-react';

import {
    imageIcEdit24px,
    hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import { request } from '../../../utils/request';
import { validateGeneralInput, validateEmailInput, myeyMessages } from '../../../utils/utils';

import ScreenReaderLabel from '../../../components/ScreenReaderLabel';

const Employee = () => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const navigate = useNavigate();

    const [employees, setEmployees] = useState([]);
    const [employeeStatus, setEmployeeStatus] = useState([]);
    const [employeeLanguage, setEmployeeLanguage] = useState([]);
    const [functionEntities, setFunctionEntities] = useState([]);
    const [entities, setEntities] = useState([]);
    const [functions, setFunctions] = useState([]);
    const screenReaderAlertRef = useRef(null);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [language, setLanguage] = useState('');
    const [entity, setEntity] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [fetchedFunctionId, setFetchedFunctionId] = useState(null); // to make sure function title is properly prefilled when editing (prevent async loading problems)
    const [functionTitle, setFunctionTitle] = useState('');
    const [employeeToEdit, setEmployeeToEdit] = useState(null);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [editSelf, setEditSelf] = useState(false);

    const [myey, setMyey] = useState(process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True'); // default to true in production, allows to create non-myey test dummy accounts with a default password for testing purposes

    const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
    const [ConfirmModalText, setConfirmModalText] = useState('');
    const [ConfirmModalFooter, setConfirmModalFooter] = useState('');
    const [hideModalCloseButton, setHideModalCloseButton] = useState(true);

    const [forceRenderKey, setForceRenderKey] = useState('');

    useEffect(() => {
        !!entities.length && setForceRenderKey(crypto.randomUUID());
        !!functions.length && setForceRenderKey(crypto.randomUUID());
        !!employeeStatus.length && setForceRenderKey(crypto.randomUUID());
        !!employeeLanguage.length && setForceRenderKey(crypto.randomUUID());
      }, [entities, entity, functionTitle, status, language]);

    const submit = async (how) => {

        setIsFormSubmitted(true);
        
        // Check if the email is valid
        if (validateEmailInput(email).isNotValid) {
            return; // Return early to prevent the modal from closing
        }

        if (validateForm()) {
            var url = '';
            let msg = '';

            var data = {
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'status': status,
                'language': language,
                'function_title': functionTitle,
                'entity': entity, // entity of the functionTitle (functionEntity)
                'entities': selectedEntities,
                'myey': myey // always true if debug features are off, to test dummy accounts without valid e-mail using the old login
            }

            if (how === 'POST') {
                url = 'all-employee-details/';
                msg = t('modal_employee_added_body', {ns: 'admin'}); // default success message
            } else {
                url = `all-employee-details/${employeeToEdit}/`
                msg = t('modal_employee_edited_body', {ns: 'admin'});
            }
            try {
                setIsLoading(true);
                var response = await request.post(url, data, how)

                if (how === 'POST') {
                    const translationKey = myeyMessages[response.myey];
                    if (translationKey) {
                        msg = t(translationKey, {ns: 'admin'});
                    } else {
                        msg = t('myey_other_error', {ns: 'admin'}); // default error if unknown error
                        console.error('No response from MyEY')
                    }
                }

                if(response.logout_required) { // own status changed, send to login page
                    navigate('/login/')
                    return
                }

                setEmployees(await request.get('all-employee-details/', {}));

                setModalVisibility(false);
                setIsFormSubmitted(false);

                setIsLoading(false);
                
                setConfirmModalText(msg);
                setConfirmModalFooter(
                    <>
                    <MotifButton
                        className="me-3"
                        onClick={() => {setConfirmModalVisibility(false);setModalVisibility(false);}}
                        disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
                    </>
                );

                setConfirmModalVisibility(true);
            } catch (error) {
                console.error('error', error)
                if (error.message === 'already_exists') {
                    setConfirmModalText(t('modal_employee_exists_body', {ns: 'admin'}));
                    setConfirmModalFooter(
                        <>
                        <MotifButton
                            className="me-3"
                            onClick={() => {setConfirmModalVisibility(false);}}
                            disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
                        </>
                    );
                    setConfirmModalVisibility(true)
                } else if (error.message === 'admin_required') { // client admin tried to de-admin the sole client admin 
                    setStatus('1'); // revert attempted change to de-admin the final client admin
                    setConfirmModalText(t('modal_employee_adminrequired_body', {ns: 'admin'}));
                    setConfirmModalFooter(
                        <>
                        <MotifButton
                            className="me-3"
                            onClick={() => {setConfirmModalVisibility(false);}}
                            disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
                        </>
                    );
                    setConfirmModalVisibility(true)
                } else {
                    console.error(error.message);
                    // Close the modal if other types of errors occurs
                    setModalVisibility(false);
                }
                setIsLoading(false)
                return
            }
        }
    }

    const fieldTranslations = {
        firstName: { key: 'firstname', ns: 'account', required: true },
        lastName: { key: 'lastname', ns: 'account', required: true },
        email: { key: 'email', ns: 'account', required: true },
        selectedEntities: { key: 'entity', ns: 'general', required: true },
        functionTitle: { key: 'function_title', ns: 'general', required: true },
        status: { key: 'status', ns: 'general', required: true },
        language: { key: 'language', ns: 'account', required: true },
    };    
    
    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
    
                if (!required) return false; // Skip non-required fields
    
                // Validate based on field type
                if (typeof fieldValue === 'string') {
                    return fieldValue.trim().length === 0; // Check for empty strings
                } else if (Array.isArray(fieldValue)) {
                    return fieldValue.length === 0; // Check for empty arrays
                } else {
                    return !fieldValue; // Check for undefined, null, or other falsy values
                }
            })
            .map(([field]) => field);
    
        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');
    
            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }
        return true;
    };    

    const clearData = () => {

        setEditMode(false);
        setIsFormSubmitted(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setStatus('');
        setLanguage('');
        setEntity('');
        setSelectedEntities([]);
        setFunctionTitle('');

    }

    useEffect(() => {
        if(!isModalVisible) { // if modal is closed, clear form data
            clearData();
        }
    }, [isModalVisible])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            setEmployees(await request.get('all-employee-details/', {}));
            setEmployeeStatus(await request.get('employee-status/', {}));
            setEmployeeLanguage(await request.get('language/', {}));
            setFunctionEntities(await request.get('function-entity'));

            setIsLoading(false);
        };

        fetchData();

    }, []);

    // First useEffect to update entities
    useEffect(() => {
    const entitiesSet = new Set(); // Will track unique entity IDs
    const tempEntities = [];

    functionEntities.forEach(({ entity }) => {
        if (!entitiesSet.has(entity.id)) {
        entitiesSet.add(entity.id);
        tempEntities.push(entity);
        }
    });

    // Update the unique entities state
    setEntities(tempEntities);
    }, [functionEntities]);

    // Second useEffect to update functions when the selected entities change
    useEffect(() => {
        // Convert selectedEntities to numbers for comparison
        const selectedEntityIds = selectedEntities.map(Number);
      
        const filteredFunctionEntities = functionEntities
          .filter(fe => selectedEntityIds.includes(fe.entity.id)) // Filter using the selected entities array
          .map(fe => ({
            ...fe.function_title,
            entityId: fe.entity.id,
            // Append entity name to the function only if more than one entity is selected
            displayName: selectedEntityIds.length > 1 ?
              `${fe.function_title.function_title} (${fe.entity.name})` : 
              fe.function_title.function_title
          }));
      
        // Update the state to hold the filtered and unique functions
        setFunctions(filteredFunctionEntities);
      
        // Clear functionTitle if the currently selected function is not applicable to any selected entities
        const functionExists = filteredFunctionEntities.some(f => f.id.toString() === functionTitle);
        if (!functionExists) {
          setFunctionTitle('');
        }
      }, [selectedEntities, functionEntities]);

    // useEffect for prefilling the function in editMode
    useEffect(() => { 
        if(editMode) {
            if (fetchedFunctionId && functions?.some(item => item.id == fetchedFunctionId)) {
                setFunctionTitle(fetchedFunctionId);
                setFetchedFunctionId(null);
            }
        }
    }, [editMode, functions, fetchedFunctionId]);

    useEffect(() => { // set the entity ID for the function title, to send to the backend to connect it to the correct FunctionEntity
        if (functionTitle) {
          // Search for the function entity with the selected function id
          const functionEntity = functionEntities.find(fe => fe.function_title.id.toString() === functionTitle);
      
          if (functionEntity && functionEntity.entity) {
            // If found, set the corresponding entity id
            setEntity(functionEntity.entity.id.toString());
          }
        } else if (functionTitle === '') {
          // If the functionTitle has been cleared, clear the entity as well
          setEntity('');
        }
      }, [functionTitle, functionEntities]);

    const setEditData = (employeeId) => {
        setEditMode(true)
        
        const selectedEmployee = employees.filter(employee => employee.id === employeeId)[0]
        setEmployeeToEdit(selectedEmployee.id);
        setFirstName(selectedEmployee.first_name);
        setLastName(selectedEmployee.last_name);
        setEmail(selectedEmployee.email);
        setStatus(selectedEmployee.status.id.toString());
        setLanguage(selectedEmployee.language.toString());
        setEntity(selectedEmployee.function_entity.entity.toString())
        const entityIds = selectedEmployee.entities?.map(entity => entity.toString());
        setSelectedEntities(entityIds);
        setFetchedFunctionId(selectedEmployee.function_entity.function_title.toString()); // don't prefill yet to avoid problems with async loading

        setEditSelf(selectedEmployee.email == localStorage.getItem('username'));

        setModalVisibility(true);

    }

    const warningForm = () => {
        setConfirmModalText(<div>
            {t('modal_employee_remove_confirm_body', {ns: 'admin'})} 
            <br />
            <p style={{fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>{firstName + ' ' + lastName}</p>
        </div>)
        setHideModalCloseButton(true);
        setConfirmModalFooter(
        <>
          <MotifButton
            variant="warn"
            className="me-3"
            onClick={() => { handleDelete() }}
            disabled={isLoading}>{t('confirm', {ns: 'general'})}</MotifButton>
          <MotifButton
            variant="primary"
            className="me-3"
            onClick={() => setConfirmModalVisibility(false)}
            disabled={isLoading}>{t('cancel', {ns: 'general'})}</MotifButton>
        </>
        );
        setConfirmModalVisibility(true);
      }

      const handleDelete = async () => {
        setIsLoading(true);

        let msg = '';
        msg = t('modal_employee_removed_body', {ns: 'admin'}) // default success message
        try {
            var response = await request.delete(`all-employee-details/${employeeToEdit}`);

            const translationKey = myeyMessages[response.myey];
            if (translationKey) {
                msg = t(translationKey, {ns: 'admin'});
            } else {
                msg = t('myey_other_error', {ns: 'admin'}); // default error if unknown error
                console.error('No response from MyEY')
            }

            setConfirmModalText(msg)
            setConfirmModalFooter(
            <>
            <MotifButton
                variant="primary"
                className="me-3"
                onClick={() => {setConfirmModalVisibility(false);setModalVisibility(false);}}
                disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
            </>
            );
            setConfirmModalVisibility(true);
            } catch (error) {
                console.error(error.message)
                if (error.message === 'delete_self') {
                    setConfirmModalText(t('modal_employee_deleteself_body', {ns: 'admin'}));
                    setConfirmModalVisibility(true)
                    setConfirmModalFooter(
                        <>
                        <MotifButton
                            variant="primary"
                            className="me-3"
                            onClick={() => {setConfirmModalVisibility(false);}}
                            disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
                        </>
                    );
                } else if (error.message === 'admin_required')  {
                    setConfirmModalText(t('modal_employee_adminrequired_body', {ns: 'admin'}));
                    setConfirmModalVisibility(true)
                    setConfirmModalFooter(
                        <>
                        <MotifButton
                            variant="primary"
                            className="me-3"
                            onClick={() => {setConfirmModalVisibility(false);}}
                            disabled={isLoading}>{t('close', {ns: 'general'})}</MotifButton>
                        </>
                    );
                }
            } finally {
                setEmployees(await request.get('all-employee-details/', {})); // refresh MotifTable data after execution
                setIsLoading(false);
            }
        };

    const columnDef = [
        {
            headerName: ' ',
            cellRenderer: ({ value }) => (
                <MotifIconButton value={value}
                    type="button"
                    title={t('tablebutton', {ns: 'admin'})}>
                    <MotifIcon src={imageIcEdit24px} />
                </MotifIconButton>
            ),
            field: 'id',
            width: 60,
            pinned: 'left',
            headerComponentParams: {
                dataColumn: false,
                headerEndComponent: (
                    <MotifIconButton aria-label="Click here to see options" type="button">
                        <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
                    </MotifIconButton>
                )
            }
        },
        {
            headerName: t('firstname', {ns: 'account'}),
            field: 'first_name',
            sortable: true,
            filter: true,
            flex: 1
        },
        {
            headerName: t('lastname', {ns: 'account'}),
            field: 'last_name',
            sortable: true,
            filter: true,
            flex: 1
        },
        {
            headerName: 'E-mail',
            field: 'email',
            sortable: true,
            filter: true,
            flex: 1
        },
        {
            headerName: t('entities', {ns: 'general'}),
            field: 'entitynames',
            sortable: true,
            filter: true,
            flex: 2
        },
        {
            headerName: t('function_title', {ns: 'general'}),
            field: 'function_title',
            sortable: true,
            filter: true,
            flex: 1
        },
        {
            headerName: t('status', {ns: 'general'}),
            field: 'status.status',
            sortable: true,
            filter: true,
            flex: 1
        }
    ]

    return (
        <>
            <div className="row justify-content-left mt-5">
                <div className="col">
                    <MotifButton variant="primary" onClick={() => setModalVisibility(true)} disabled={isLoading}>{t('new_employee', {ns: 'admin'})}</MotifButton>
                </div>
            </div>

            {employees ?
            <div className="row justify-content-left mt-5">
                <div className="col">
                    <MotifTable className='pb-5'
                        columnDefs={columnDef}
                        rowData={employees}
                        onRowClicked={row => setEditData(row.data.id)}
                        onCellKeyDown={event => {
                            if (event.event.key === 'Enter') {
                                setEditData(event.data.id);
                            }
                        }}
                        compact={true}
                        zebra={true}
                        pagination={employees?.length > 30 ? true : false}
                        paginationPageSize="25"
                         />
                </div>
            </div>
            : null}

            <MotifModal show={isModalVisible} onClose={() => {setModalVisibility(false);}}>
                <MotifModalHeader>{editMode ? t('edit_employee', {ns: 'admin'}) : t('new_employee', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifLabel>{t('firstname', {ns: 'account'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-firstname' message={`${t('firstname', {ns: 'account'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={firstName}
                            onChange={event => setFirstName(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={50}
                            disabled={editMode || isLoading}
                            aria-labelledby='sr-firstname'
                        />
                        {(firstName.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('lastname', {ns: 'account'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-lastname' message={`${t('lastname', {ns: 'account'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={lastName}
                            onChange={event => setLastName(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={50}
                            disabled={editMode || isLoading}
                            aria-labelledby='sr-lastname'
                        />
                        {(lastName.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>E-mail</MotifLabel>
                        <ScreenReaderLabel id='sr-email' message={`${t('email', {ns: 'account'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={email}
                            onChange={event => setEmail(event.target.value)}
                            required={true}
                            maxLength={256}
                            disabled={editMode || isLoading}
                            aria-labelledby='sr-email'
                        />
                        {(email.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                        {(validateEmailInput(email).isNotValid && isFormSubmitted) && <MotifErrorMessage>{t('invalid_email', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
 
                    <MotifFormField>
                        <MotifLabel id="select-entity">{t('entity', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-entity' message={`${t('entity', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            id="select-entities"
                            multiple={true}
                            // showSelectAllButton={true} bug: pressing select all does not trigger 'setSelectedEntities'
                            key={forceRenderKey}
                            value={selectedEntities}
                            onChange={val => setSelectedEntities(val)}
                            ariaLabelledBy="sr-entity"
                            visibleOptions='3'
                            disabled={isLoading}
                        >
                            {entities.map(
                                item => (<MotifOption
                                    key={item.id.toString()}
                                    value={item.id.toString()}
                                >{item.name}
                                </MotifOption>)
                                )
                            }
                        </MotifSelect>
                        {(selectedEntities.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                        <MotifInlineMessage>{t('modal_employee_entity_message', {ns: 'admin'})}</MotifInlineMessage>
                    </MotifFormField>
                    
                    <MotifFormField>
                        <MotifLabel id="select-function">{t('function_title', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-function' message={`${t('funtion_title', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            key={forceRenderKey}
                            value={functionTitle}
                            filter={true}
                            onChange={val => setFunctionTitle(val)} 
                            ariaLabelledBy="sr-function"
                            visibleOptions='3'
                            disabled={isLoading}
                        >
                            {functions?.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.displayName}</MotifOption>))}
                        </MotifSelect>
                        {(functionTitle.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-status">{t('status', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-status' message={`${t('status', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect 
                            key={forceRenderKey}
                            value={status}
                            onChange={val => setStatus(val)}
                            ariaLabelledBy="sr-status"
                            visibleOptions='3'
                            disabled={isLoading}
                        >
                            {employeeStatus.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.status}</MotifOption>))}
                        </MotifSelect>
                        {(status.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-language">{t('language', {ns: 'account'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-language' message={`${t('language', {ns: 'account'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect 
                            key={forceRenderKey}
                            value={language}
                            onChange={val => setLanguage(val)}
                            ariaLabelledBy="sr-language"
                            visibleOptions='3'
                            disabled={isLoading}
                        >
                            {employeeLanguage.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.language}</MotifOption>))}
                        </MotifSelect>
                        {(language.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        {/* Conditionally render the checkbox if debug features are enabled */}
                        {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
                            <MotifCheckbox
                                id="myey-checkbox"
                                name="myey-checkbox"
                                onChange={() => setMyey(!myey)} // Toggle the state
                                checked={myey} // Controlled checkbox state
                            >
                                MyEY (uncheck if testing with fake account)
                            </MotifCheckbox>
                        ) : (
                            // Hide checkbox and force `myey` to true in production
                            <input type="hidden" value={true} />
                        )}
                    </MotifFormField>


                    {editSelf ? t('modal_employee_editself_message', {ns: 'admin'}) : null}

                </MotifModalBody>
                <MotifModalFooter>
                    <div
                        ref={screenReaderAlertRef}
                        aria-live="assertive"
                        className="visually-hidden"
                        role="alert"
                    ></div>
                    <div className="row" style={{width: '100%'}}>
                        <div className="col-6 text-left">
                        {editMode && employeeToEdit ?
                        <MotifButton 
                            variant='warn' 
                            disabled={isLoading}
                            onClick={() => { warningForm() }}
                        >
                            {t('remove', {ns: 'general'})}
                        </MotifButton>
                        : null}
                        </div>
                    </div>
                    <div className="col-6 text-right d-flex justify-content-end">
                        <MotifButton 
                            variant="primary"
                            size="medium" 
                            type="button" 
                            disabled={isLoading} 
                            onClick={() => { submit(editMode === false ? 'POST' : 'PUT') }}
                            style={{margin: '5px'}}
                        >
                            {editMode === false ? t('add', {ns: 'general'}) : t('edit', {ns: 'general'})}
                        </MotifButton>
                        <MotifButton
                            size="medium"
                            variant="secondary"
                            type="button"
                            disabled={isLoading}
                            onClick={() => setModalVisibility(false)}
                            style={{margin: '5px'}}
                        >
                            {t('cancel', {ns: 'general'})}
                        </MotifButton>
                    </div>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isConfirmModalVisible} onClose={() => setConfirmModalVisibility(false)}>
                <MotifModalHeader closeModalButton={hideModalCloseButton}>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>{ConfirmModalText}</MotifModalBody>
                <MotifModalFooter>{ConfirmModalFooter}</MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default Employee