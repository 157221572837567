import React, {useEffect, useRef } from 'react'

import { MakeRequests } from '../../utils/request'

const FetchDashboardData = (completed, setOriginalTasks, tasks, setTasks, setSelectedTasks, setSubthemes, setThemes, setEntities, setCountries, setFiscalYears, setIsLoading) => {  
    const request = new MakeRequests()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
    
            let param = completed === 1 ? '-completed' : '';
            
            try {
                // Use Promise.all to wait for both requests to finish
                const fetchTasks = await request.get(`task-overview${param}/`, {});

                const allTasks = { controls: fetchTasks.control_tasks, monitoring: fetchTasks.monitoring_tasks };
                setOriginalTasks(allTasks);
                setTasks(allTasks);
                setSelectedTasks(allTasks);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false); // Set loading to false only after both requests have been handled
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
      const sortArrayById = (array) => array.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
      
      let themes = [];
      let subthemes = [];
      let entities = [];
      let countries = [];
      let fiscalYears = [];
    
      const extractFromControl = (control) => {
        // Extract unique subthemes
        if (control.risk?.subtheme && !subthemes.some(st => st.id === String(control.risk.subtheme.id))) {
          subthemes.push({
            id: String(control.risk.subtheme.id),
            description: control.risk.subtheme.description_code
          });
        }

        // Extract unique themes
        if (control.risk?.subtheme?.theme && !themes.some(t => t.id === String(control.risk.subtheme.theme.id))) {
          themes.push({
            id: String(control.risk.subtheme.theme.id),
            description: control.risk.subtheme.theme.description
          });
        }

        // Extract unique entities and countries
        control.entity?.forEach(entity => {
          if (!entities.some(e => e.id === String(entity.id))) {
            entities.push({ id: String(entity.id), name: entity.name });
          }
          if (entity.country && !countries.some(c => c.id === String(entity.country.id))) {
            countries.push({ id: String(entity.country.id), country: entity.country.country });
          }
        });
      };
    
      const extractTasks = (taskList, completed) => {
        taskList.forEach(task => {
          let controlsData = [];
          
          if (completed && task.snapshot) {
            if (task.snapshot.monitoring?.controls) {
              // Handling for completed monitoring tasks with multiple controls.
              controlsData = task.snapshot.monitoring.controls.map(control => control.control);
            } else if (task.snapshot.control) {
              // Handling for a completed control task with a single control.
              controlsData = [task.snapshot.control];
            }
          } else {
            // Handling for non-completed tasks.
            // For monitoring tasks, `task.controls` is expected to be an array of control objects.
            // For control tasks, the task itself acts as a single control object.
            controlsData = task.monitoring?.controls ? task.monitoring.controls : [task.control];
          }
      
          controlsData.forEach(control => {
            extractFromControl(control);
          });
      
          if (task.fiscal_year && !fiscalYears.includes(task.fiscal_year)) {
            fiscalYears.push(task.fiscal_year);
          }
        });
      };
      
      // Extract from non-completed control tasks
      if(tasks.controls?.length > 0) {
        extractTasks(tasks.controls, completed);
      }
      // Extract from non-completed monitoring tasks
      if(tasks.monitoring?.length > 0) {
        extractTasks(tasks.monitoring, completed);
      }
    
      // Sort the results
      themes = sortArrayById(themes);
      subthemes = sortArrayById(subthemes);
      entities.sort((a, b) => a.name.localeCompare(b.name));
      countries.sort((a, b) => a.country.localeCompare(b.country));
      fiscalYears.sort((a, b) => b.localeCompare(a));

      // Update state
      setThemes(themes)
      setSubthemes(subthemes);
      setEntities(entities);
      setCountries(countries);
      setFiscalYears(fiscalYears);
    }, [tasks]);    
} 

export default FetchDashboardData