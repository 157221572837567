import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
	MotifTable,
	MotifChip,
	MotifIcon,
	MotifIconButton,
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
} from '@ey-xd/motif-react'

import { MotifActionIcAssignment24px } from '@ey-xd/motif-icon';

import {
	actionIcAssignment24px,
	hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import ControlTaskDescription from '../../components/ControlTaskDescription';
import MonitoringTaskDescription from '../../components/MonitoringTaskDescription';

const TaskTable = ({ selectedTasks, activeTab, navigate, completed, hideStartdate, hideDeadline, sortStartdate, sortDeadline, overviewSwitch }) => {

	const { t } = useTranslation();
	const { userRole, isSuperuser } = useOutletContext();

	const [isModalVisible, setModalVisibility] = useState(false);
	const [modalHeaderText, setModalHeaderText] = useState(t('task_description', { ns: 'general' }));
	const [modalBodyText, setModalBodyText] = useState('');
	const [modalFooterText, setModalFooterText] = useState('');

	const [hasReviewer, setHasReviewer] = useState();
	const [hasControlNumber, setHasControlNumber] = useState(false);
	const [hasMonitoringNumber, setHasMonitoringNumber] = useState(false);

	useEffect(() => {
		const someControlNumberExists = selectedTasks?.controls?.some(task => {
			if (completed === 1) {
				return task.snapshot?.control?.control_number?.length > 0;
			} else {
				return task.control?.control_number?.length > 0;
			}
		});

		const someMonitoringNumberExists = selectedTasks?.monitoring?.some(task => {
			if (completed === 1) {
				return task.snapshot?.monitoring?.monitoring_number?.length > 0;
			} else {
				return task.monitoring?.monitoring_number?.length > 0;
			}
		});

		setHasControlNumber(someControlNumberExists);
		setHasMonitoringNumber(someMonitoringNumberExists);
	}, [selectedTasks]);

	useEffect(() => { // show reviewer column if any control task has a reviewer
		const someReviewerExists = selectedTasks.controls?.some(task => task?.control?.has_reviewer === true || task?.snapshot?.control?.has_reviewer === true);
		setHasReviewer(someReviewerExists);
	}, [selectedTasks]);

	const getTask = (value, taskType) => {
		return taskType === 'control'
			? selectedTasks.controls?.find(task => task.id == value)
			: selectedTasks.monitoring?.find(task => task.id == value);
	};

	const renderTaskDescription = (task, taskType) => {
		return taskType === 'control' ? (
			<ControlTaskDescription controlTask={task} taskFinal={completed} />
		) : (
			<MonitoringTaskDescription monitorTask={task} taskFinal={completed} />
		);
	};
	
	const renderModalFooter = (url, value, taskType) => {
		return (
			<>
				
				<MotifButton
					className="me-3"
					onClick={() => navigate(url, { state: completed == 1 ? { taskId: value, completed: 1 } : { taskId: value } })}
				>
					{t('open_task', { ns: 'general' })}
				</MotifButton>
				<MotifButton
					className="me-3"
					onClick={() => setModalVisibility(false)}
				>
					{t('close', { ns: 'general' })}
				</MotifButton>
			</>
		);
	};
	
	const taskPopUp = (value) => {
		const taskType = activeTab === 'controls' ? 'control' : 'monitoring';
		const url = taskType === 'control' ? '../performcontroloverview/performcontrol' : '../performmonitoringoverview/performmonitoring';
	
		const task = getTask(value, taskType);
		
		const taskContent = renderTaskDescription(task, taskType);
		setModalBodyText(taskContent);
	
		const modalFooterContent = renderModalFooter(url, value, taskType);
		setModalFooterText(modalFooterContent);
	
		setModalVisibility(true);
	};

	const getChipVariant = (executionDate, deadline, isCompleted) => {
		if (!isCompleted) return { variant: 'default', label: 'Not completed' };
	
		const execution = new Date(executionDate);
		const dueDate = new Date(deadline);
		const timeDifference = execution - dueDate;
		const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	
		if (daysDifference <= 0) return { variant: 'success', label: t('task_completed_on_time', {ns: 'screenreader'}) };
		if (daysDifference <= 7) return { variant: 'monochrome-light', label: t('task_completed_1week', {ns: 'screenreader'}) };
		if (daysDifference <= 30) return { variant: 'warning', label: t('task_completed_1month', {ns: 'screenreader'}) };
		return { variant: 'error', label: t('task_completed_later', {ns: 'screenreader'}) };
	};	

	// const ActionCellKeyboardHandler = (params) => {
	//   const e = params.event;
	//   if (
	//     e.key === 'Tab' ||
	//     e.key === 'Enter' ||
	//     e.key === ' ' // Spacebar
	//   ) {
	//     return true;
	//   }
	//   return false;
	// };


	// A function to get the snapshot path if needed
	const getFieldPath = (baseField, completed) =>
		completed ? `snapshot.${baseField}` : baseField;

	const columnDefC = [
		{
			headerName: ' ',
			cellRenderer: ({ value }) => {

				return (
					<MotifIconButton value={value}
						type="button"
						title={t('tablebutton', { ns: 'perform_control' })}>
						<MotifIcon src={MotifActionIcAssignment24px} />
					</MotifIconButton>
				);
			},
			field: 'id',
			// suppressKeyboardEvent: ActionCellKeyboardHandler, not working properly yet
			width: 60,
			pinned: 'left',
			headerComponentParams: {
				dataColumn: false,
				template:
					'<div aria-label="Details">' +
					'</div>',
				headerEndComponent: (
					<MotifIconButton aria-label="Click here to see options" type="button">
						<MotifIcon src={hardwareIcKeyboardArrowDown24px} />
					</MotifIconButton>
				)
			}
		},
		{
			headerName: '#',
			field: getFieldPath('control.control_number', completed),
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
			hide: !hasControlNumber,
		},
		{
			headerName: t('subtheme', { ns: 'general' }),
			field: getFieldPath('control.risk.subtheme.description_code', completed),
			resizable: true,
			minWidth: 100,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('risk', { ns: 'general' }),
			field: getFieldPath('control.risk.risk', completed),
			minWidth: 170,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 3,
		},
		{
			headerName: t('control', { ns: 'general' }),
			field: getFieldPath('control.control', completed),
			minWidth: 140,
			sortable: true,
			filter: true,
			wrapText: true,
			resizable: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('status', { ns: 'general' }),
			field: 'status.description',
			width: completed ? 140 : 250,
			sortable: true,
			cellRenderer: ({ data }) => {
				const { variant: chipVariant, label: chipLabel } = completed === 1
					? getChipVariant(data.execution_date, data.deadline, completed)
					: { variant: data.status.chipVariant, label: data.status.description };
		
				return (
					<div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
						<MotifChip
							variant={chipVariant}
							aria-label={`${data.status.description}. ${chipLabel}`}
						>
							{data.status.description}
						</MotifChip>
					</div>
				);
			},
		},
		
		{
			headerName: t('startdate', { ns: 'general' }),
			field: 'start_date',
			width: 160,
			sortable: true,
			filter: true,
			sort: sortStartdate,
			hide: hideStartdate,
		},
		{
			headerName: t('deadline', { ns: 'general' }),
			field: 'deadline',
			width: 160,
			sortable: true,
			filter: true,
			sort: sortDeadline,
			hide: hideDeadline,
		},
		{
			headerName: t('responsible', { ns: 'general' }),
			// Handle showing archived data for completed tasks
			field: completed === 1 ? 'archived_responsible_fullname' : 'responsible_fullname',
			cellRenderer: (params) => {
				if (completed === 1) {
					return params.data.archived_responsible_fullname || params.data.archived_function_responsible || t('none', { ns: 'general' });
				}
				return params.value || t('none', { ns: 'general' });
			},
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !overviewSwitch,
			flex: 1,
		},
		{
			headerName: t('reviewer', { ns: 'general' }),
			// Handle showing archived data for completed tasks
			field: completed === 1 ? 'archived_reviewer_fullname' : 'reviewer_fullname',
			cellRenderer: (params) => {
				if (completed === 1) {
					return params.data.archived_reviewer_fullname || params.data.archived_function_reviewer || t('none', { ns: 'general' });
				}
				return params.value || t('none', { ns: 'general' });
			},
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !overviewSwitch || !hasReviewer,
			flex: 1,
		},
		{
			headerName: t('fiscal_year', { ns: 'general' }),
			field: 'fiscal_year',
			width: 130,
			sortable: true,
			filter: true,
			hide: !completed
		},
	]

	const columnDefM = [
		{
			headerName: ' ',
			cellRenderer: ({ value }) => {
				return (
					<MotifIconButton value={value}
						type="button"
						title={t('tablebutton', { ns: 'perform_monitoring' })}>
						<MotifIcon src={actionIcAssignment24px} />
					</MotifIconButton>
				);
			},
			field: 'id',
			width: 60,
			pinned: 'left',
			headerComponentParams: {
				dataColumn: false,
				headerEndComponent: (
					<MotifIconButton aria-label="Click here to see options" type="button">
						<MotifIcon src={hardwareIcKeyboardArrowDown24px} />
					</MotifIconButton>
				)
			}
		},
		{
			headerName: '#',
			field: getFieldPath('monitoring.monitoring_number', completed),
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
			hide: !hasMonitoringNumber,
		},
		{
			headerName: t('subtheme', { ns: 'general' }),
			field: getFieldPath('monitoring.controls', completed),
			cellRenderer: ({ data }) => {
				const completed = data.status.id === 1;
				const controlSource = completed ? data.snapshot?.monitoring?.controls.map(item => item.control) : data.monitoring?.controls;

				const uniqueSubthemes = Array.from(
					new Set(controlSource?.map(control => control?.risk?.subtheme?.description_code))
				);
				const subthemes = uniqueSubthemes.join(', ');

				return (
					<>
						{subthemes}
					</>
				);
			},
			minWidth: 170,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('risks', { ns: 'general' }),
			field: getFieldPath('monitoring.controls', completed),
			cellRenderer: ({ data }) => {
				const completed = data.status.id === 1;
				const controlSource = completed ? data.snapshot?.monitoring?.controls.map(item => item.control) : data.monitoring?.controls;

				// Note: For displaying risk description, if it comes under control in snapshot, address it accordingly
				const uniqueRisks = Array.from(
					new Set(controlSource?.map(control => control?.risk?.risk || control?.risk?.risk))
				);
				const risks = uniqueRisks.join(', ');

				return (
					<>
						{risks}
					</>
				);
			},
			minWidth: 170,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('monitoring', { ns: 'general' }),
			field: getFieldPath('monitoring.monitoring', completed),
			minWidth: 170,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('monitoring_type', { ns: 'general' }),
			field: getFieldPath('monitoring.monitoring_type.description', completed),
			minWidth: 170,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
		},
		{
			headerName: t('status', { ns: 'general' }),
			field: 'status.description',
			width: completed ? 140 : 250,
			sortable: true,
			cellRenderer: ({ data }) => {
				const { variant: chipVariant, label: chipLabel } = completed === 1
					? getChipVariant(data.execution_date, data.deadline, completed)
					: { variant: data.status.chipVariant, label: data.status.description };
		
				return (
					<div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
						<MotifChip
							variant={chipVariant}
							aria-label={`${data.status.description}. ${chipLabel}`}
						>
							{data.status.description}
						</MotifChip>
					</div>
				);
			},
		},
		
		{
			headerName: t('startdate', { ns: 'general' }),
			field: 'start_date',
			width: 160,
			sortable: true,
			filter: true,
			hide: hideStartdate,
			sort: sortStartdate,
		},
		{
			headerName: t('deadline', { ns: 'general' }),
			field: 'deadline',
			width: 160,
			sortable: true,
			filter: true,
			sort: sortDeadline,
			hide: hideDeadline,
		},
		{
			headerName: t('responsible', { ns: 'general' }),
			// Handle showing archived data for completed tasks
			field: completed === 1 ? 'archived_responsible_fullname' : 'responsible_fullname',
			cellRenderer: (params) => {
				if (completed === 1) {
					return params.data.archived_responsible_fullname || params.data.archived_function_responsible || t('none', { ns: 'general' });
				}
				return params.value || t('none', { ns: 'general' });
			},
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !overviewSwitch,
			flex: 1,
		},
		{
			headerName: t('reviewer', { ns: 'general' }),
			// Handle showing archived data for completed tasks
			field: completed === 1 ? 'archived_reviewer_fullname' : 'reviewer_fullname',
			cellRenderer: (params) => {
				if (completed === 1) {
					return params.data.archived_reviewer_fullname || params.data.archived_function_reviewer || t('none', { ns: 'general' });
				}
				return params.value || t('none', { ns: 'general' });
			},
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !overviewSwitch,
			flex: 1,
		},
		{
			headerName: t('fiscal_year', { ns: 'general' }),
			field: 'fiscal_year',
			width: 130,
			sortable: true,
			filter: true,
			hide: !completed
		},
	]

	// const cellFocusedHandler = (params) => {
	//   if (params.column.colId === 'id'){ // Pas dit aan naar de juiste kolomidentificatie
	//     const cellRenderer = params.api.getCellRendererInstances({
	//       rowNodes: [params.api.getDisplayedRowAtIndex(params.rowIndex)],
	//       columns: [params.column],
	//     });
	//     if (cellRenderer && cellRenderer.length > 0) {
	//       cellRenderer[0].focusButton();
	//     }
	//   }
	// }

	return (
		<>
			<div className="row justify-content-center me-2 my-4">
				{activeTab === 'controls' && selectedTasks?.controls?.length > 0 && (
					<div className="col">
						<MotifTable className='pb-5'
							title={
								completed ? (
									<div tabIndex="0">
										{t('control_tasks_completed', { count: selectedTasks?.controls.length, ns: 'general' })}
									</div>
								) : (
									<div tabIndex="0">
										{t('control_tasks', { ns: 'general' })}
									</div>
								)
							}
							description={completed ?
								<div tabIndex="0">
									{/* status colors legend */}
									<small>
										<strong>{t('status_tooltip_title', { ns: 'general' })}:&nbsp;</strong>
										<span>
											
												{t('status_tooltip_green', { ns: 'general' })} |&nbsp;
												{t('status_tooltip_grey', { ns: 'general' })} |&nbsp;
												{t('status_tooltip_orange', { ns: 'general' })} |&nbsp;
												{t('status_tooltip_red', { ns: 'general' })}
										</span>
									</small>
								</div>
							: null}
							columnDefs={columnDefC}
							rowData={selectedTasks?.controls}
							onRowClicked={row => taskPopUp(row.data.id)}
							onCellKeyDown={row => {
								if (row.event.key === 'Enter') {
									taskPopUp(row.data.id);
								}
							}}
							compact={true}
							zebra={true}
							pagination={selectedTasks?.controls?.length > 15 ? true : false}
							paginationPageSize="15"
						// onCellFocused={cellFocusedHandler} Not working properly yet
						/>
					</div>
				)}
				{activeTab === 'monitoring' && selectedTasks?.monitoring?.length > 0 && (
					<div className="col">
						<MotifTable className='pb-5'
							title={
								completed ? (
									<div tabIndex="0">
										{t('monitoring_tasks_completed', { count: selectedTasks?.monitoring.length, ns: 'general' })}
									</div>
								) : (
									<div tabIndex="0">
										{t('monitoring_tasks', { ns: 'general' })}
									</div>
								)
							}

							// Calculate the rating percentages for display
							description={completed ? (() => {
								const ratingCounts = [0, 0, 0, 0, 0, 0]; // Extra index [5] to include rating 5

								// Count occurrences of each rating (1-5)
								selectedTasks?.monitoring.forEach(task => {
									const rating = task.rating;
									if (rating >= 1 && rating <= 5) {
										ratingCounts[rating]++;
									}
								});

								// Generate percentage text for each rating
								const ratingPercentages = ratingCounts
									.map((count, rating) => {
										if (rating > 0 && count > 0) {
											return `${rating}: ${(count / selectedTasks?.monitoring.length * 100).toFixed(1)}%`;
										} else if (rating > 0) {
											return `${rating}: 0.0%`; // Handle cases with zero count for a rating
										}
										return null;
									})
									.filter(text => text) // Remove nulls from index 0
									.join(' | ');

									return (
										<div tabIndex="0">
											{`${t('rating_distribution', { ns: 'general' })}: ${ratingPercentages}`}
											<br />
											{/* status colors legend */}
											<small>
												<strong>{t('status_tooltip_title', { ns: 'general' })}:&nbsp;</strong>
												<span>
														{t('status_tooltip_green', { ns: 'general' })} |&nbsp;
														{t('status_tooltip_grey', { ns: 'general' })} |&nbsp;
														{t('status_tooltip_orange', { ns: 'general' })} |&nbsp;
														{t('status_tooltip_red', { ns: 'general' })}
												</span>
											</small>
										</div>
									)})() : null}

							columnDefs={columnDefM}
							rowData={selectedTasks?.monitoring}
							onRowClicked={row => taskPopUp(row.data.id)}
							onCellKeyDown={row => {
								if (row.event.key === 'Enter') {
									taskPopUp(row.data.id);
								}
							}}
							compact={true}
							zebra={true}
							pagination={selectedTasks?.monitoring?.length > 20 ? true : false}
							paginationPageSize="15"
						/>
					</div>
				)}
			</div>
			<MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
				<MotifModalHeader>{modalHeaderText}</MotifModalHeader>
				<MotifModalBody>{modalBodyText}</MotifModalBody>
				<MotifModalFooter>{modalFooterText}</MotifModalFooter>
			</MotifModal>
		</>
	);
}

export default TaskTable;