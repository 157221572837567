import React, { useState, useEffect, useRef } from 'react';
import { json, useLocation, useNavigate, ScrollRestoration } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
    MotifInput,
    MotifLabel,
    MotifFormField,
    MotifSelect,
    MotifOption,
    MotifTextArea,
    MotifButton,
    MotifSlider,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifRadioButton,
    MotifRadioButtonGroup,
    MotifDatePicker,
    MotifMessage,
    MotifErrorMessage,
    MotifTooltip,
    MotifInlineMessage,
    MotifIcon,
    MotifIconButton,
    MotifTable
} from '@ey-xd/motif-react';

import { actionIcHelpOutline24px, actionIcDeleteForever24px, contentIcFilterList24px, contentIcClear24px, actionIcAssignment24px, hardwareIcKeyboardArrowDown24px } from '@ey-xd/motif-react/assets/icons';

import { MakeRequests } from '../../utils/request';
import { formatDate, validateDescriptionInput, validateGeneralInput } from '../../utils/utils';

import RiskFilter from './RiskFilter'

import { tooltitle } from '../../utils/utils';

import ScreenReaderLabel from '../../components/ScreenReaderLabel';

const AddControl = () => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const { state } = useLocation();
    const { controlId } = state || {};
    const navigate = useNavigate();
    const baseUrl = 'create-control/'
    const screenReaderAlertRef = useRef(null);

    const [control, setControl] = useState('');
    const [risk, setRisk] = useState('');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [controlType, setControlType] = useState('');
    const [controlLevel, setControlLevel] = useState('');
    const [frequency, setFrequency] = useState('');
    const [responsible, setResponsible] = useState('');
    const [reviewer, setReviewer] = useState('');
    const [notifyOnFailure, setNotifyOnFailure] = useState('');
    const [description, setDescription] = useState('');
    const [proof, setProof] = useState('');
    const [netRisk, setNetRisk] = useState('1');
    const [startDate, setStartDate] = useState(null);
    const [deadline, setDeadline] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [idSubmittedControl, setIdSubmittedControl] = useState(null)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [maxRanking, setMaxRanking] = useState('');
    const [riskItems, setRiskItems] = useState([]);
    const [controlTypeItems, setControlTypeItems] = useState([]);
    const [controllevelItems, setControlLevelItems] = useState([]);
    const [frequencyItems, setFrequencyItems] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [responsibleItems, setResponsibleItems] = useState([]);
    const [reviewerItems, setReviewerItems] = useState([]);
    const [notifyOnFailureItems, setNotifyOnFailureItems] = useState([]);
    const [riskEntities, setRiskEntities] = useState({});
    const [selectedRiskEntities, setSelectedRiskEntities] = useState([])

    const [isModalVisible, setModalVisibility] = useState(false);
    const [isModalAddItemVisible, setModalAddItemVisibility] = useState(false);

    const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
    const [isDeletedModalVisible, setDeletedModalVisibility] = useState(false);
    // State to hold fetched tasks and selected tasks during deletion
    const [pendingTasks, setPendingTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);

    // filter: belastingmiddel, proces
    const [initialSubthemeItems, setInitialSubthemeItems] = useState([]);
    const [initialProcessItems, setInitialProcessItems] = useState([]);

    const [filteredSubthemeItems, setFilteredSubthemeItems] = useState([]);
    const [filteredProcessItems, setFilteredProcessItems] = useState([])
    const [filteredRiskItems, setFilteredRiskItems] = useState([])

    const [subtheme, setSubtheme] = useState([]);
    const [process, setProcess] = useState([]);

    const [isFiltered, setIsFiltered] = useState(false);

    const [subthemeItems, setSubthemeItems] = useState([]);
    const [processItems, setProcessItems] = useState([]);

    const [controlNumber, setControlNumber] = useState('');

    const [isModalFiltersVisible, setModalFiltersVisibility] = useState(false);
    // end filter
    const request = new MakeRequests();

    useEffect(() => {
        // Immediately set items to empty if no entities are selected
        if (selectedEntities.length === 0) {
            setResponsibleItems([]);
            setReviewerItems([]);
            setNotifyOnFailureItems([]);
            return; // Exit the effect early as there's nothing more to do
        }
    
        // Function to filter out the selected employees based on their IDs
        const filterOutByIds = (employeeList, idsToFilterOut) => {
            return employeeList.filter(
                (employee) => !idsToFilterOut.includes(employee.id.toString())
            );
        };
      
        // Define a function to check if selected employees are still eligible
        const checkIfStillEligible = (employeeIdList, eligibleEntities) => {
            return employeeIdList.every((employeeId) => {
                const employee = employees.find((emp) => emp.id.toString() === employeeId);
                return employee?.entities.some((entityId) =>
                    eligibleEntities.includes(entityId.toString()) // Ensure comparison consistency
                );
            });
        };
      
        // Check if currently selected responsible, reviewer, and notify on failure are still eligible
        const currentSelectionIds = [
            responsible,
            reviewer,
            notifyOnFailure
        ].filter(id => id); // Filter out falsy values
      
        // Use selectedEntities directly for checking eligibility
        const allAreEligible = checkIfStillEligible(currentSelectionIds, selectedEntities);
      
        if (!allAreEligible) {
            // If not all are eligible, reset the fields
            setResponsible('');
            setReviewer('');
            setNotifyOnFailure('');
        }
      
        // Filter employees based on both entity eligibility
        const filteredEmployees = employees.filter((employee) =>
            employee.entities.some((entityId) => selectedEntities.includes(entityId.toString()))
        );
      
        // Sorting function
        const sortByFirstName = (a, b) => a.first_name.toUpperCase().localeCompare(b.first_name.toUpperCase());
      
        // Apply filtering based on current selections and update state
        const responsibleFiltered = filterOutByIds(filteredEmployees, [reviewer, notifyOnFailure].filter(Boolean)).sort(sortByFirstName);
        const reviewerFiltered = filterOutByIds(filteredEmployees, [responsible, notifyOnFailure].filter(Boolean)).sort(sortByFirstName);
        const notifyOnFailureFiltered = filterOutByIds(filteredEmployees, [responsible, reviewer].filter(Boolean)).sort(sortByFirstName);
      
        // Update the state variables with filtered eligible employees
        setResponsibleItems(responsibleFiltered);
        setReviewerItems(reviewerFiltered);
        setNotifyOnFailureItems(notifyOnFailureFiltered);
        
    }, [responsible, reviewer, notifyOnFailure, selectedEntities, employees]);

    const fieldTranslations = {
        risk: { key: 'risk', ns: 'general', required: true },
        controlNumber: { key: 'control_number', ns: 'general', required: false },
        control: { key: 'control', ns: 'general', required: true },
        selectedEntities: { key: 'entity', ns: 'general', required: true },
        controlType: { key: 'control_type', ns: 'general', required: true },
        controlLevel: { key: 'control_approach', ns: 'general', required: true },
        startDate: { key: 'first_startdate', ns: 'general', required: true },
        deadline: { key: 'first_deadline', ns: 'general', required: true },
        expiry: { key: 'expiry_optional', ns: 'general', required: false },
        frequency: { key: 'frequency', ns: 'general', required: true },
        responsible: { key: 'responsible', ns: 'general', required: true },
        reviewer: { key: 'reviewer_optional', ns: 'add_control', required: false },
        notifyOnFailure: { key: 'notify_on_failure', ns: 'general', required: true },
        description: { key: 'description', ns: 'general', required: true },
        proof: { key: 'proof', ns: 'general', required: true },
    };      
    
    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
    
                if (!required) return false; // Skip non-required fields
    
                // Validate based on field type
                if (typeof fieldValue === 'string') {
                    return fieldValue.trim().length === 0; // Check for empty strings
                } else if (Array.isArray(fieldValue)) {
                    return fieldValue.length === 0; // Check for empty arrays
                } else {
                    return !fieldValue; // Check for undefined, null, or other falsy values
                }
            })
            .map(([field]) => field);
    
        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');
    
            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }
    
        return true;
    };

    const submitForm = async (how) => {

        setIsFormSubmitted(true);
    
        if (validateForm()) {

            var startDateFormat = formatDate(startDate);
            var deadlineFormat = formatDate(deadline);
            if (expiry !== null ) { 
                var expiryFormat = formatDate(expiry); 
            } else {
                var expiryFormat = null
            }

            let hasReviewer = reviewer.length > 0 ? '1' : '0'; // if reviewer is selected, mark as control item requiring reviewer. to be able to alert the user when there is no reviewer assigned.

            var controlBody = {
                'control': control,
                'description': description,
                'proof': proof,
                'net_risk': netRisk,
                'control_level': controlLevel,
                'control_type': controlType,
                'frequency': frequency,
                'risk': risk,
                'responsible': responsible,
                'reviewer': reviewer ? reviewer : null,
                'notify_on_failure': notifyOnFailure,
                'entity': selectedEntities,
                'start_date': startDateFormat,
                'deadline': deadlineFormat,
                'expiry': expiryFormat,
                'deleted': 0,
                'control_number': controlNumber,
                'has_reviewer': hasReviewer
            };

            if (how === 'POST') {
                try {
                    setIsLoading(true)
                    await request.post(baseUrl, controlBody, how).then((result) => { setIdSubmittedControl(result['id']) });
                    setModalAddItemVisibility(true);
                } catch (error) {
                    console.error(error.message)
                } finally {
                    setIsLoading(false)
                }

            } else if (how === 'PUT') {

                try {
                    setIsLoading(true)
                    await request.post(`${baseUrl}${controlId ? controlId : idSubmittedControl}/`, controlBody, how)
                    setModalAddItemVisibility(true);
                }
                catch (error) {
                    console.error(error.message)
                } finally {
                    setIsLoading(false)
                }
            }
        }
    }

    // const handleSelectTask = (taskId) => {
    //     const index = selectedTasks.indexOf(taskId);
    //     let newSelectedTasks = [...selectedTasks];
    
    //     if (index > -1) {
    //         // Task already selected, remove it
    //         newSelectedTasks.splice(index, 1);
    //     } else {
    //         // Task not selected, add it
    //         newSelectedTasks.push(taskId);
    //     }
    
    //     setSelectedTasks(newSelectedTasks);
    // };
    
    const fetchPendingTasksForControl = async () => {
        try {
            setIsLoading(true);
            const data = await request.get(`del-related-ctask?related_delete=${controlId}`, {});
            // Check if tasks were returned
            if (data && data.length > 0) {
                setPendingTasks(data);
                setDeleteModalVisibility(true);
            } else {
                // If no tasks are found, proceed to delete the control
                await deleteControl(controlId);
            }
        } catch(error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const deleteControl = async () => {
        try {
            setIsLoading(true);
            // Delete selected tasks if any
            if (selectedTasks.length > 0) {
                await request.post(`del-related-ctask`, {"tasks": selectedTasks}, 'DELETE');
            }

            // Soft delete the control
            await request.post(`${baseUrl}${controlId}/`, {"deleted": true}, 'PATCH');

            setDeleteModalVisibility(false); // Close the current modal
            setDeletedModalVisibility(true); // Show the confirmation modal
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getControlData = async () => {
        setIsLoading(true);
        try {
            var controlData = await request.get(`${baseUrl}${controlId}`, {});
            setControl(controlData['control']);
            setRisk(controlData['risk']['id'].toString());
            setControlType(controlData['control_type']['id'].toString());
            setControlLevel(controlData['control_level']['id'].toString());
            setFrequency(controlData['frequency']['id'].toString());
            if (controlData['responsible'] !== null) {
                setResponsible(controlData['responsible'].toString());
            }
            if (controlData['reviewer'] !== null) {
                setReviewer(controlData['reviewer'].toString());
            }
            if (controlData['notify_on_failure'] !== null) {
                setNotifyOnFailure(controlData['notify_on_failure'].toString());
            }
            setDescription(controlData['description']);
            setProof(controlData['proof']);
            setNetRisk(controlData['net_risk'].toString());
            const entityIds = controlData.entity?.map(entity => entity.id.toString());
            setSelectedEntities(entityIds);
            setStartDate(new Date(controlData['start_date']));
            setDeadline(new Date(controlData['deadline']));
            if (controlData['expiry']) {
                setExpiry(new Date(controlData['expiry']));
            }
            if (controlData['control_number']) {
                setControlNumber(controlData['control_number']);
            }
        } catch (error) {
            console.error(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                var scaleData = await request.get('key-risk-data'); // for the max netto risk slider
                var scaleNumber = scaleData[0].scale.number;
                setMaxRanking((scaleNumber * scaleNumber).toString())

                const initialRiskItems = await request.get(`risk`, {});
                setRiskItems(initialRiskItems);
                setFilteredRiskItems(initialRiskItems);
                setProcessItems(initialRiskItems);
        
                setControlTypeItems(await request.get(`control-type`, {}));
                setControlLevelItems(await request.get(`control-level`, {}));
                setFrequencyItems(await request.get(`frequency`, {}));

                var employees = await request.get(`all-employee-details`, {})
                var filteredEmployees = employees.filter((item) => item.status.id === 1 || item.status.id === 2); // Only client-admins and users
                setEmployees(filteredEmployees);
                setResponsibleItems(filteredEmployees);
                setReviewerItems(filteredEmployees);
                setNotifyOnFailureItems(filteredEmployees);

                setSubthemeItems(await request.get(`subtheme`, {}));

                controlId != null && await getControlData();
            } catch (error) {
                console.error(error.message)
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchRiskEntities = async () => {
            // Check if riskItems is empty or undefined
            if (!riskItems || riskItems.length === 0) return;

            setIsLoading(true);
            try {
                // Extract risk IDs from the riskItems array
                const riskIds = riskItems.map(item => item.id);

                // Make a single request to the endpoint with risk IDs as query parameters
                const response = await request.get('risk-department/bulk-risk-entities', {
                    risk_ids: riskIds.join(','),  // Convert the array to a comma-separated string
                });                

                // Set the response data to state
                setRiskEntities(response);
            } catch (error) {
                console.error('Error fetching risk entities:', error.message);
            } finally {
                setIsLoading(false);
            }
        };

        // Call the function to fetch data
        fetchRiskEntities();
    }, [riskItems]);

    useEffect(() => {

        if (riskEntities[risk]) {
            setSelectedRiskEntities(riskEntities[risk]) // Populate entities select with relevant entities for the risk
        }

    }, [risk, riskEntities])

    useEffect(() => { // populate Riskfilter dropdown with relevant items
        const filteredSubthemeItems = subthemeItems.filter(
            subthemeItem => riskItems.some(
                riskItem => riskItem.subtheme.id == subthemeItem.id
            )
        );
        setInitialSubthemeItems(filteredSubthemeItems);
        setFilteredSubthemeItems(filteredSubthemeItems);

        const uniqueProcessItems = [...new Set(processItems.map(item => item.process))];
        const filteredProcessItems = uniqueProcessItems.filter(
            processItem => riskItems.some(
                riskItem => riskItem.process == processItem
            )
        );
        setInitialProcessItems(filteredProcessItems)
        setFilteredProcessItems(filteredProcessItems);
    }, [subthemeItems, processItems]);

    useEffect(() => { 
        if (subtheme && subtheme.length > 0) {
            const filteredProcessItems = initialProcessItems.filter(
                processItem => riskItems.some(
                    riskItem => subtheme.includes(riskItem.subtheme.id.toString()) && riskItem.process == processItem
                )
            );
            setFilteredProcessItems(filteredProcessItems);
        } else {
            setFilteredProcessItems(initialProcessItems);
        }
    }, [subtheme]);

    useEffect(() => {
        if (process && process.length > 0) {
            const filteredSubthemeItems = initialSubthemeItems.filter(
                subthemeItem => riskItems.some(
                    riskItem => process.includes(riskItem.process) && riskItem.subtheme.id == subthemeItem.id
                )
            );
            setFilteredSubthemeItems(filteredSubthemeItems);
        } else {
            setFilteredSubthemeItems(initialSubthemeItems);
        }
    }, [process]);

    const setFilters = () => {
        setIsFiltered(true);
        setModalFiltersVisibility(false)
        const filters = [{ field: 'subtheme', value: subtheme }, { field: 'process', value: process }];
    
        if (filters) {
            const filteredArray = riskItems.filter(item => {
                return filters.every(filter => {
                    // If filter is applied and if filter data is non-empty
                    if (Array.isArray(filter.value) && filter.value.length > 0) {
                        // Filter field is 'subtheme', so compare the subtheme.id of riskItem with selected subtheme values
                        if (filter.field === 'subtheme') {
                            return filter.value.some(val => item[filter.field].id.toString() === val);
                        }
                        // Filter field is 'proces', so compare the process of risk item with selected process values
                        else if (filter.field === 'process') {
                            return filter.value.includes(item[filter.field]);
                        }
                    } 
                    // If no filter is applied for this field
                    else {
                        return true;
                    }
                });
            });
            setFilteredRiskItems(filteredArray);
        }
    };    

    const clearFilters = () => {
        setIsFiltered(false);

        setSubtheme([]);
        setProcess([]);

        setFilteredRiskItems(riskItems);
        setFilteredSubthemeItems(initialSubthemeItems);
        setFilteredProcessItems(initialProcessItems);
    }

    const columnDef = [
        {
          headerName: ' ',
          cellRenderer: (props) => {
            const isChecked = selectedTasks.includes(props.data.id);
      
            return (
              <input
                type="checkbox"
                checked={isChecked}
                readOnly={true} // check box toggle is handled by onRowClicked
              />
            );
          },
          field: 'id',
          width: 43,
          pinned: 'left',
          headerComponentParams: {
            dataColumn: false,
            headerEndComponent: (
              <MotifIconButton aria-label="Click here to see options" type="button">
                <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
              </MotifIconButton>
            ),
          },
        },
        {
          headerName: t('control', { ns: 'general' }),
          field: 'control.control',
          minWidth: 140,
          sortable: true,
          filter: true,
          wrapText: true,
          resizable: true,
          autoHeight: true,
          flex: 2,
        },
        {
          headerName: t('status', { ns: 'general' }),
          field: 'status.description',
          width: 250,
          sortable: true,
          filter: true,
          wrapText: true,
          resizable: true,
          autoHeight: true,
        },
        {
          headerName: t('startdate', { ns: 'general' }),
          field: 'start_date',
          width: 180,
          sortable: true,
          filter: true,
          sort: 'asc',
        },
        {
          headerName: t('deadline', { ns: 'general' }),
          field: 'deadline',
          width: 180,
          sortable: true,
          filter: true,
        },
        {
          headerName: t('responsible', { ns: 'general' }),
          field: 'responsible_fullname',
          minWidth: 140,
          wrapText: true,
          sortable: true,
          filter: true,
          flex: 1,
        },
        {
          headerName: t('reviewer', { ns: 'general' }),
          field: 'reviewer_fullname',
          minWidth: 140,
          wrapText: true,
          sortable: true,
          filter: true,
          hide: !reviewer,
          flex: 1,
        },
      ];
      
    // Handler function to select/deselect tasks
    const handleSelectTask = (id) => {
        setSelectedTasks((prevSelectedTasks) =>
          prevSelectedTasks.includes(id)
            ? prevSelectedTasks.filter((taskId) => taskId !== id)
            : [...prevSelectedTasks, id]
        );
    };
      
    // onRowClicked function for handling row clicks
    const handleRowClick = (params) => {
        handleSelectTask(params.data.id); // Call the function to toggle the task selection
    };
    
    const handleKeyDown = (event) => {
    if (event.event.key === 'Enter') {  // Check for Enter key
        const rowData = event.node.data;  // Retrieve row data from the event
        handleRowClick({ data: rowData }); // Pass the data to handleRowClick function
        }
    };

    useEffect(() => {
        // There is a bug in MotifSlider (v6.5.0). Setting min attribute to '1' glitches out the sliders. It will show the values 1 too high (e.g. when the slider is on its lowest, 1, it will show as 2). The bug has been reported to the Motif team 12-9-2024
        // This useEffect serves as a workaround so 0 values can't be submitted.
        // There is also seemingly a bug where you can pull the slider under the minimum (e.g. -1) and over the maximum, this also gets prevented by this useEffect.
        // parseInt because MotifSlider sets its values and attributes as strings, not integers.
        if (parseInt(netRisk) <= 0) {
            setNetRisk('1');
        }

        if (parseInt(netRisk) >= parseInt(maxRanking)) {
            setNetRisk(maxRanking.toString());
        }
      }, [netRisk]);

    return (
        <>
        <title>{controlId ? t('edit_control', {ns: 'menu'}) : t('add_control', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{controlId ? t('edit_control', {ns: 'menu'}) : t('add_control', {ns: 'menu'})}</h1>
        </div>
            <style jsx="true">{`
                .motif-date-picker-wrapper {
                    max-width: 18rem;
                    
                }
                .flex-container {
                    display: flex;
                    align-items: center;
                }
                .motif-modal-size-xl {  /* make the xl modal wider */
                    width: 70%;
                    max-width: 100%; /* overwrite the default max-width */
                    height: 30%;
                    overflow: auto;
                }
                .motif-table .motif-table-wrapper {
                    /* for unknown reason, it locks the MotifTable wrapper size to 300px height and width, making the table very tiny. Override with this. */
                    width: 100%;
                    height: auto;
                    padding: 1%;
                }
            `}</style>            

            <div className="row justify-content-center mx-2 mt-5"
                style={{overflowY:'scroll',
                    height: '80vh'
                }}>
                <div className="col-6">
                    <div className="d-flex flex-row-reverse">
                        {isFiltered && <MotifIconButton className="p-1" type="button" onClick={clearFilters}><MotifIcon src={actionIcDeleteForever24px} /></MotifIconButton>}
                        <MotifIconButton className="p-1" type="button" onClick={() => setModalFiltersVisibility(true)}><MotifIcon src={contentIcFilterList24px} /><MotifLabel>Filter</MotifLabel></MotifIconButton>
                    </div>

                    <MotifFormField>
                        <MotifLabel id="select-risk">{t('risk', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-risks' message={`${t('risk', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            disabled={isLoading}
                            value={risk}
                            onChange={val => setRisk(val)}
                            ariaLabelledBy="sr-risks"
                        >
                            {filteredRiskItems.map(item => (<MotifOption key={item.id.toString()}
                                value={item.id.toString()}
                            >{item.risk}
                            </MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredRiskItems.length + ' results'}</MotifMessage>
                        {(risk.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_risk', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id='control-number'>{t('control_number', {ns: 'general'})}</MotifLabel>
                        <MotifInput value={controlNumber?controlNumber:''}
                            onChange={(event) => {
                              setControlNumber(validateGeneralInput(event.target.value).cleanedValue);
                            }}
                            aria-labbeledby='control-number'
                            maxLength={10} />
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('control', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-control' message={`${t('control', {ns: 'general'})}, ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={control}
                            disabled={isLoading}
                            onChange={event => {
                                setControl(validateDescriptionInput(event.target.value).cleanedValue);
                                validateForm()
                            }}
                            aria-labelledby='sr-control'
                            required={true}
                            maxLength={100} />
                        {(control.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_name', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-entity">{t('entity', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-entities' message={`${t('entity', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            id="select-entities"
                            multiple={true}
                            disabled={isLoading}
                            value={selectedEntities}
                            onChange={val => setSelectedEntities(val)}
                            ariaLabelledBy="sr-entities"
                        >
                            {selectedRiskEntities.map(
                                item => (<MotifOption
                                    key={item.id.toString()}
                                    value={item.id.toString()}
                                    
                                >{item.name}
                                </MotifOption>)
                            )
                            }
                        </MotifSelect>
                        {risk.length == 0 ? <MotifInlineMessage>{t('valid_riskentity', {ns: 'add_control'})}</MotifInlineMessage> : ''}
                        {(selectedEntities.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_entity', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <div className="d-flex flex-row mb-3">
                        <MotifFormField tabIndex='0' ariaLabelledBy='sr-control-type' className="me-5">
                            <MotifLabel>{t('control_type', {ns: 'general'})}</MotifLabel>
                            <ScreenReaderLabel id='sr-control-type' message={`${t('control_type', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                            <MotifRadioButtonGroup>
                                {controlTypeItems.map(item => (
                                    <MotifRadioButton
                                        key={item.id}
                                        disabled={isLoading}
                                        id={"control-type" + item.id}
                                        name={"control-type" + item.id}
                                        value={item.id.toString()}
                                        checked={item.id.toString() === controlType}
                                        onChange={event => setControlType(event.target.value)}
                                        ariaLabelledBy='sr-control-type'
                                    >
                                        {item.description}
                                    </MotifRadioButton>))}
                            </MotifRadioButtonGroup>
                            {(controlType.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                                {t('valid_type', {ns: 'add_control'})}</MotifErrorMessage>}
                        </MotifFormField>


                        <MotifFormField tabIndex='0' ariaLabelledBy='sr-control-approach'>
                            <MotifLabel>{t('control_approach', {ns: 'general'})}</MotifLabel>
                            <ScreenReaderLabel id='sr-control-approach' message={`${t('control_approach', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                            <MotifRadioButtonGroup>
                                {controllevelItems.map(item => (
                                    <MotifRadioButton
                                        key={item.id}
                                        id={"control-level" + item.id}
                                        name={"control-level" + item.id}
                                        value={item.id.toString()}
                                        disabled={isLoading}
                                        checked={item.id.toString() === controlLevel}
                                        onChange={event => setControlLevel(event.target.value)}
                                        ariaLabelledBy='sr-control-approach'
                                    >
                                        {item.description}
                                    </MotifRadioButton>))}
                            </MotifRadioButtonGroup>
                            {(controlLevel.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                                {t('valid_level', {ns: 'add_control'})}</MotifErrorMessage>}
                        </MotifFormField>
                    </div>

                    
                    <div className="d-flex flex-wrap justify-content-between" style={{ width: '100%' }}>
                    { !controlId ? // hide startdate and deadline in edit mode, the first task has already been created
                        <MotifFormField tabIndex='0' style={{ flex: '1' }}>
                            <MotifLabel>{t('first_startdate', {ns: 'general'})}</MotifLabel>
                            <ScreenReaderLabel id='sr-startdate' message={`${t('first_startdate', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                            <MotifDatePicker
                                calendarType="iso8601"
                                value={startDate}
                                disabled={isLoading}
                                onChange={date => setStartDate(date)}
                                showLeadingZeros={true}
                                format="dd-MM-yyyy"
                                yearPlaceholder="YYYY"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                maxDate={deadline ? deadline : null}
                                ariaLabelledBy='sr-startdate'
                            />
                            {(startDate === null && isFormSubmitted) && <MotifErrorMessage>
                                {t('valid_startdate', {ns: 'add_control'})}</MotifErrorMessage>}
                        </MotifFormField>
                        : null}

                        { !controlId ? // hide startdate and deadline in edit mode, the first task has already been created
                        <MotifFormField tabIndex='0' style={{ flex: '1' }}>
                            <MotifLabel>{t('first_deadline', {ns: 'general'})}</MotifLabel>
                            <ScreenReaderLabel id='sr-deadline' message={`${t('first_deadline', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                            <MotifDatePicker
                                calendarType="iso8601"
                                value={deadline}
                                disabled={isLoading}
                                onChange={date => setDeadline(date)}
                                showLeadingZeros={true}
                                format="dd-MM-yyyy"
                                yearPlaceholder="YYYY"
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                minDate={startDate ? startDate : null}
                                ariaLabelledBy='sr-deadline'
                            />
                            {(deadline === null && isFormSubmitted) && <MotifErrorMessage>
                                {t('valid_deadline', {ns: 'add_control'})}</MotifErrorMessage>}
                        </MotifFormField>
                        : null }

                        <MotifFormField tabIndex='0' style={{ width: '100%' }}>
                            <div className="flex-container" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                <MotifLabel>{t('expiry_optional', {ns: 'general'})}</MotifLabel>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MotifDatePicker
                                        calendarType="iso8601"
                                        value={expiry}
                                        disabled={isLoading}
                                        onChange={date => setExpiry(date)}
                                        showLeadingZeros={true}
                                        format="dd-MM-yyyy"
                                        yearPlaceholder="YYYY"
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        minDate={deadline ? deadline : null}
                                    />
                                    <MotifTooltip
                                        id="tooltip-2"
                                        trigger={
                                            <MotifIconButton aria-label="Tooltip" type="button" aria-describedby="tooltip-2">
                                                <MotifIcon src={actionIcHelpOutline24px} />
                                            </MotifIconButton>
                                        }
                                        variant="alt"
                                        placement="top"
                                        flip={true}
                                        hideCloseButton={false}
                                    >
                                        {t('expiry_tooltip', {ns: 'add_control'})}
                                    </MotifTooltip>
                                { expiry ?
                                <MotifIconButton aria-label="Clear" type="button" onClick={() => {setExpiry(null)}} style={{ marginTop: '8px' }}>
                                    <MotifIcon src={contentIcClear24px} /> 
                                </MotifIconButton> : ''}
                                </div>
                            
                            </div>
                        </MotifFormField>
                    </div>

                    { !controlId ? // hide frequency in edit mode, the first task has already been created
                    <MotifFormField>
                        <MotifLabel id="select-frequency">{t('frequency', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-frequency' message={`${t('frequency', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            value={frequency}
                            disabled={isLoading}
                            ariaLabelledBy='sr-frequency'
                            onChange={val => setFrequency(val)}>
                            {frequencyItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
                        </MotifSelect>
                        {(frequency.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_frequency', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>
                    : null }

                    <MotifFormField>
                        <MotifLabel id="select-responsible">{t('responsible', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-responsible' message={`${t('responsible', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            value={responsible}
                            disabled={isLoading}
                            onChange={val => setResponsible(val)}
                            ariaLabelledBy={'sr-responsible'}
                            filter>
                            {responsibleItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                        {(responsible.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_responsible', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-reviewer">{t('reviewer_optional', {ns: 'add_control'})}</MotifLabel>
                        <MotifSelect
                            ariaLabelledBy={'select-reviewer'}
                            value={reviewer}
                            disabled={isLoading}
                            onChange={val => setReviewer(val)}
                            filter>
                            {reviewerItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-nof">{t('notify_on_failure', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-nof' message={`${t('notify_on_failure', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            ariaLabelledBy={'sr-nof'}
                            value={notifyOnFailure}
                            disabled={isLoading}
                            onChange={val => setNotifyOnFailure(val)}
                            filter>
                            {notifyOnFailureItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                        {(notifyOnFailure.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_notifyonfailure', {ns: 'add_control'})}</MotifErrorMessage>}
                        {(responsible.length > 0 && notifyOnFailure.length > 0 && responsible == notifyOnFailure) && <MotifErrorMessage>
                            {t('valid_responsible_notifyonfailure', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('description', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-description' message={`${t('description', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifTextArea value={description}
                            aria-labelledby='sr-description'
                            disabled={isLoading}
                            onChange={event => setDescription(validateDescriptionInput(event.target.value).cleanedValue)}
                            rows={5} 
                            maxLength={2000} />
                        {(description.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_description', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('proof', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-proof' message={`${t('proof', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifTextArea value={proof}
                            aria-labelledby='sr-proof'
                            disabled={isLoading}
                            onChange={event => setProof(validateDescriptionInput(event.target.value).cleanedValue)}
                            rows={5}
                            maxLength={2000} />
                        {(proof.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_proof', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <div className="flex-container">
                            <MotifLabel id="net_risk" style={{ width: 'auto', marginBottom: '0%' }}>{t('net_risk', {ns: 'general'})}&nbsp;</MotifLabel>
                            <MotifTooltip
                                id="tooltip-2"
                                disabled={isLoading}
                                trigger={
                                <MotifIconButton aria-label="Tooltip" type="button" aria-describedby="tooltip-2" style={{ display: 'inline-block' }}>
                                    <MotifIcon src={actionIcHelpOutline24px} />
                                </MotifIconButton>
                                }
                                variant="alt"
                                placement="left"
                                flip={true}
                                hideCloseButton={false}
                            >
                                {t('netrisk_tooltip', {ns: 'add_control'})}
                            </MotifTooltip>
                        </div>
                        { maxRanking ? // wait til maxRanking is set or it may visually glitch
                        <MotifSlider
                            disabled={isLoading}
                            value={netRisk}
                            onChange={event => setNetRisk(event.target.value)}
                            id="slider-net_risk"
                            min="0"
                            max={maxRanking.toString()}
                        />
                        : null}
                    </MotifFormField>

                    <div className="d-flex flex-row">
                        <MotifButton
                            className="mt-2"
                            disabled={isLoading}
                            onClick={() => {

                                submitForm(controlId | idSubmittedControl ? 'PUT' : 'POST')
                            }}>
                            {controlId ? t('edit', {ns: 'general'}) : t('add', {ns: 'general'})}
                        </MotifButton>

                        {controlId &&
                            <MotifButton
                                className="m-2"
                                variant="warn"
                                onClick={() => setModalVisibility(true)}
                                disabled={isLoading}>
                                {t('remove', {ns: 'general'})}
                            </MotifButton>
                        }

                    </div>
                    <div
                        ref={screenReaderAlertRef}
                        aria-live="assertive"
                        className="visually-hidden"
                        role="alert"
                    ></div>

                </div>
            </div>

            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{t('modal_delete_confirm_header', {ns: 'add_control'})}</MotifModalHeader>
                <MotifModalBody>{t('modal_delete_confirm_body', {ns: 'add_control'})}</MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={fetchPendingTasksForControl}>
                        {t('confirm', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        type="button"
                        onClick={() => setModalVisibility(false)}>
                        {t('cancel', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isDeleteModalVisible} onClose={() => setDeleteModalVisibility(false)} size='xl' style={{minHeight: "55%"}}>
                <MotifModalHeader>{t('modal_delete_confirm_header', {ns: 'add_control'})}</MotifModalHeader>
                <MotifModalBody>
                <>
                    {pendingTasks.length > 0 ? (
                        <>
                            <div>{t('modal_delete_pendingtasks', {ns: 'add_control'})}</div>
                            <MotifTable className='mb-3 h-auto'
                                columnDefs={columnDef}
                                rowData={pendingTasks}
                                onRowClicked={handleRowClick}
                                onCellKeyDown={(event) => handleKeyDown(event)} 
                                compact={true}
                                zebra={true}
                            />
                        </>
                        ) : null}
                    </>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={deleteControl}>
                        {t('confirm', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isDeletedModalVisible} onClose={() => setDeletedModalVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{t('modal_delete_confirm_header', {ns: 'add_control'})}</MotifModalHeader>
                <MotifModalBody>
                    {t('modal_deleted_body', {ns: 'add_control'})}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={() => navigate('/controls/')}>
                        {t('modal_added_button_controls', {ns: 'add_control'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isModalAddItemVisible} onClose={() => setModalAddItemVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{!controlId ? t('modal_added_header', {ns: 'add_control'}) : t('modal_edited_header', {ns: 'add_control'})}</MotifModalHeader>
                <MotifModalBody>{!controlId ? t('modal_added_body', {ns: 'add_control'}) : t('modal_edited_body', {ns: 'add_control'})}</MotifModalBody>
                <MotifModalFooter>

                    <MotifButton size="medium" type="button" onClick={() => {
                        setModalAddItemVisibility(false);
                        window.location.reload(false);
                        navigate('/controls/add_control/', { state: { controlId: null } })
                    }}>
                        {t('modal_added_button_newcontrol', {ns: 'add_control'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        type="button"
                        onClick={() => navigate('/monitoring/add_monitoring/', { state: { controlId: null } })}>
                        {t('modal_added_button_newmonitoring', {ns: 'add_control'})}
                    </MotifButton>
                    <MotifButton size="medium" type="button" onClick={() => navigate('/controls/', { state: { controlId: null } })}>
                        {t('modal_added_button_controls', {ns: 'add_control'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
            <RiskFilter setFilters={setFilters} setIsFiltered={setIsFiltered} clearFilters={clearFilters} isModalFiltersVisible={isModalFiltersVisible} setModalFiltersVisibility={setModalFiltersVisibility} subtheme={subtheme} setSubtheme={setSubtheme} process={process} setProcess={setProcess} filteredSubthemeItems={filteredSubthemeItems} filteredProcessItems={filteredProcessItems} />
            <ScrollRestoration />


        </>
    )
}

export default AddControl