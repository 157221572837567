import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifFormField,
    MotifIconButton,
    MotifIcon,
    MotifSelect,
    MotifOption,
    MotifLabel,
} from '@ey-xd/motif-react'

import {
    actionIcDeleteForever24px,
} from '@ey-xd/motif-react/assets/icons';

const TableFilter = ({ colors, activeTab, isLoading, filtered, setFiltered, setChartFiltered, tasks, selectedTasks, setSelectedTasks, subthemes, themes, entities, countries, fiscalYears, completed }) => {

    const { t } = useTranslation();

    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState([]);
    const [selectedSubtheme, setSelectedSubtheme] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedRating, setSelectedRating] = useState('');
    const [negativeFeedback, setNegativeFeedback] = useState('');

    const filterTasks = () => {
        const filtered = { controls: [], monitoring: [] };

        // Filter control tasks
        filtered.controls = tasks.controls.filter(task => {
            // Adjust to correctly handle completed control tasks with a snapshot
            const control = (completed === 1 && task.snapshot) ? task.snapshot.control : task.control;

            // Filtering logic for year, theme, subtheme, entity, and country
            const yearMatches = !selectedYear.length || selectedYear.includes(task.fiscal_year);

            const themeMatches = !selectedTheme.length || selectedTheme.includes(control.risk.subtheme.theme.description);

            const subthemeMatches = !selectedSubtheme.length || selectedSubtheme.includes(control.risk.subtheme.description_code);

            const entityMatches = !selectedEntity.length || control.entity.some(entity => {
                return selectedEntity.includes(entity.name);
            });

            const countryMatches = !selectedCountry.length || control.entity.some(entity => {
                return entity.country && selectedCountry.includes(entity.country.country);
            });

            return yearMatches && themeMatches && subthemeMatches && entityMatches && countryMatches;
        });

        // Filter monitoring tasks
        tasks.monitoring.forEach(monitoringTask => {
            // Correctly handle completed monitoring tasks with snapshot data
            const controls = (completed === 1 && monitoringTask.snapshot) ? monitoringTask.snapshot.monitoring.controls.map(c => c.control) : monitoringTask.monitoring.controls;

            const isMatchingMonitoringTask = controls.some(control => {
                const riskInfo = control.risk;
                const entities = control.entity;

                const themeMatches = !selectedTheme.length || selectedTheme.includes(riskInfo.subtheme.theme.description);

                const subthemeMatches = !selectedSubtheme.length || selectedSubtheme.includes(riskInfo.subtheme.description_code);

                const entityMatches = !selectedEntity.length || control.entity.some(entity => {
                    return selectedEntity.includes(entity.name);
                });

                const countryMatches = !selectedCountry.length || control.entity.some(entity => {
                    return entity.country && selectedCountry.includes(entity.country.country);
                });

                return themeMatches && subthemeMatches && entityMatches && countryMatches;
            });

            if (isMatchingMonitoringTask) {
                const yearMatches = !selectedYear.length || selectedYear.includes(monitoringTask.fiscal_year);
                const ratingMatches = !selectedRating.length || selectedRating.includes(monitoringTask.rating.toString());
                const negativeFeedbackMatches = 
                    negativeFeedback === '' || // "All" case - allow any feedback value
                    monitoringTask.negativeFeedback === negativeFeedback || // Match true/false
                    (negativeFeedback === '' && monitoringTask.negativeFeedback === null); // Include nulls for "All"


                if (yearMatches && ratingMatches && negativeFeedbackMatches) {
                    filtered.monitoring.push(monitoringTask);
                }
            }
        });

        setSelectedTasks(filtered);
    };

    // Handle changes in the year, theme, subtheme, country, entity, and rating selects
    const handleYearChange = (selectedOptions) => {
        setSelectedYear(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleThemeChange = (selectedOptions) => {
        setSelectedTheme(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleSubthemeChange = (selectedOptions) => {
        setSelectedSubtheme(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleEntityChange = (selectedOptions) => {
        setSelectedEntity(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleCountryChange = (selectedOptions) => {
        setSelectedCountry(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleRatingChange = (selectedOptions) => {
        setSelectedRating(selectedOptions);
        setFiltered(true);
        setChartFiltered(false);
    };

    const handleNegativeFeedbackChange = (selectedOption) => {
        setNegativeFeedback(selectedOption === 'yes' ? true : selectedOption === 'no' ? false : '');
        setFiltered(true);
        setChartFiltered(false);
    };

    useEffect(() => {
        filterTasks(selectedYear, selectedTheme, selectedSubtheme, selectedEntity, selectedCountry, selectedRating, negativeFeedback);
    }, [selectedYear, selectedTheme, selectedSubtheme, selectedEntity, selectedCountry, selectedRating, negativeFeedback]);

    const clearFilters = () => {
        setSelectedTasks(tasks);
        setSelectedYear([]);
        setSelectedTheme([]);
        setSelectedSubtheme([]);
        setSelectedCountry([]);
        setSelectedEntity([]);
        setSelectedRating([]);
        setNegativeFeedback('');
    
        setFiltered(false);
        setChartFiltered(false);
    };
    

    return (
        <>
            {completed ? (
                <div className="col">
                    <MotifFormField style={{ width: "250px" }}>
                        <MotifLabel id="select-year" aria-label='select fiscal years'>{t('fiscal_year', { ns: 'general' })}</MotifLabel>
                        <MotifSelect
                            filter={true}
                            multiple={true}
                            value={selectedYear}
                            onChange={handleYearChange}
                            disabled={isLoading}
                        >
                            {fiscalYears?.map((item, i) => (<MotifOption key={i} value={item}>{item}</MotifOption>))}
                        </MotifSelect>
                    </MotifFormField>
                </div>
            ) : null}
            <div className="col">
                <MotifFormField style={{ width: "250px" }} aria-label='select themes'>
                    <MotifLabel id="theme">{t('theme', { ns: 'general' })}</MotifLabel>
                    <MotifSelect
                        ariaLabelledBy='select-theme'
                        filter={true}
                        multiple={true}
                        value={selectedTheme}
                        onChange={handleThemeChange}
                        disabled={isLoading}
                    >
                        {themes?.map((item, i) => (<MotifOption key={i} value={item.description}>{item.description}</MotifOption>))}
                    </MotifSelect>
                </MotifFormField>
            </div>
            <div className="col">
                <MotifFormField style={{ width: "250px" }} aria-label='select subthemes'>
                    <MotifLabel id="select-subtheme">{t('subtheme', { ns: 'general' })}</MotifLabel>
                    <MotifSelect
                        ariaLabelledBy='select-subtheme'
                        filter={true}
                        multiple={true}
                        value={selectedSubtheme}
                        onChange={handleSubthemeChange}
                        disabled={isLoading}
                    >
                        {subthemes?.map((item, i) => (<MotifOption key={i} value={item.description}>{item.description}</MotifOption>))}
                    </MotifSelect>
                </MotifFormField>
            </div>
            <div className="col">
                <MotifFormField style={{ width: "250px" }} aria-label='select countries'>
                    <MotifLabel id="select-country">{t('country', { ns: 'general' })}</MotifLabel>
                    <MotifSelect
                        filter={true}
                        multiple={true}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        disabled={isLoading}
                    >
                        {countries?.map((item, i) => (<MotifOption key={i} value={item.country}>{item.country}</MotifOption>))}
                    </MotifSelect>
                </MotifFormField>
            </div>
            <div className="col">
                <MotifFormField style={{ width: "250px" }} aria-label='select entities'>
                    <MotifLabel id="select-entity">{t('entities', { ns: 'general' })}</MotifLabel>
                    <MotifSelect
                        filter={true}
                        multiple={true}
                        value={selectedEntity}
                        onChange={handleEntityChange}
                        disabled={isLoading}
                    >
                        {entities?.map((item, i) => (<MotifOption key={i} value={item.name}>{item.name}</MotifOption>))}
                    </MotifSelect>
                </MotifFormField>
            </div>
            {activeTab === 'monitoring' ? (
                <>
                    <div className="col">
                        <MotifFormField style={{ width: "250px" }} aria-label='filter by monitoring task ratings'>
                            <MotifLabel id="select-rating">{t('monitoring_rating', { ns: 'perform_monitoring' })}</MotifLabel>
                            <MotifSelect
                                value={selectedRating}
                                onChange={handleRatingChange}
                                disabled={isLoading}
                                multiple={true}
                            >
                                <MotifOption key={1} value={'1'}>1</MotifOption>
                                <MotifOption key={2} value={'2'}>2</MotifOption>
                                <MotifOption key={3} value={'3'}>3</MotifOption>
                                <MotifOption key={4} value={'4'}>4</MotifOption>
                                <MotifOption key={5} value={'5'}>5</MotifOption>
                            </MotifSelect>
                        </MotifFormField>
                    </div>
                    <div className="col">
                    <MotifFormField style={{ width: "250px" }} aria-label='show monitoring tasks with negative feedback on controls'>
                        <MotifLabel id="select-rating">{t('negative_control_feedback', { ns: 'general' })}</MotifLabel>
                        <MotifSelect
                            ariaLabelledBy='select-negativefeedback'
                            value={negativeFeedback === '' ? '' : (negativeFeedback ? 'yes' : 'no')}
                            onChange={handleNegativeFeedbackChange}
                            disabled={isLoading}
                        >
                            <MotifOption key={0} value="">&nbsp;</MotifOption>
                            <MotifOption key={1} value="yes">{t('yes', { ns: 'general' })}</MotifOption>
                            <MotifOption key={2} value="no">{t('no', { ns: 'general' })}</MotifOption>
                        </MotifSelect>
                    </MotifFormField>
                </div>
            </>
            ) : null}
            <div className="col">
            {filtered ? <MotifIconButton className="p-1" type="button" onClick={clearFilters}><MotifIcon src={actionIcDeleteForever24px} title="Reset filters" /><small style={{ color: colors.grey }}>Reset filters</small></MotifIconButton> : null}
            </div>
        </>
    );
};

export default TableFilter;
